import {
  AggregationRequest,
  AggregationType,
  IndexName,
  ResponseAggregate,
  ResponseAggregation,
  ResponsePayload,
} from 'elasticsearch/types'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as Generic from 'generic/genericRedux'
import * as React from 'react'

import { RasaContext } from 'context'

export interface ElasticsearchProps<P> extends Generic.ComponentActionProps {
  results: P,
  raw: any,
}

export abstract class ElasticsearchComponent<T, P extends ElasticsearchProps<T>, S> extends React.Component<P, S> {
  public static contextType = RasaContext
  public reportName = ''
  protected communityId: string

  private readonly elasticsearchEndpointUrl: string = '/dataset'
  private readonly indexName: IndexName

  constructor(p: P, indexName: IndexName) {
    super(p)
    this.indexName = indexName
  }

  public abstract searchPayload(): any

  public abstract parseResponse(response: ResponsePayload): Promise<T>

  public getAggregation(aggregation: ResponseAggregation, key: any): ResponseAggregate {
    return aggregation.buckets.find((b: ResponseAggregate) => b.key === key)
  }

  public addAggregation(payload: any, request: AggregationRequest): any {
    if (request.child) {
      request.childs = request.childs || []
      request.childs.push({...request.child, name: 'child'})
    }
    if (request.unique_on) {
      request.childs = request.childs || []
      request.childs.push({
        type: AggregationType.cardinality,
        field: request.unique_on,
        name: 'unique',
        extra: {
          precision_threshold: '40000',
        }
      })
    }
    if (request.childs) {
      return payload.aggregation(
        request.type,
        request.field,
        request.extra,
        request.name,
        (subquery: any) => {
          request.childs.forEach((x) => {
            subquery = this.addAggregation(subquery, {...x})
          })
          return subquery
        })
    } else {
      return payload.aggregation(request.type, request.field, request.extra, request.name)
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.communityId = activeCommunity.communityId
      this.search()
    })
  }

  public search() {
    const search = this.searchPayload()
    if (search) {
      this.query(search)
          .then((result: ResponsePayload) => Promise.all([result, this.parseResponse(result)]))
          .then(([raw, results]) => {
            this.props.propertiesChanged({
              raw,
              results,
            })
          })
          .catch((error: any) => {
            //eslint-disable-next-line no-console
            console.error('Error querying ES', error)
            this.searchError()
          })
    }
  }

  protected searchError(): void {

  }

  protected query(payload: any): Promise<any> {
    return this.queryIndex(payload, this.indexName)
  }

  protected queryIndex(payload: any, indexName: string) {
    if (indexName && this.communityId) {
      const url: string = AjaxWrapper.getServerUrl() + this.getElasticsearchUrl() + `?index=${indexName}`
      return AjaxWrapper.ajax(
        url,
        HttpMethod.POST,
        {
          ...payload,
          report_name: this.reportName,
        },
      )
    } else {
      return Promise.reject('Params must be provided for ES Search of ' + indexName)
    }
  }

  private getElasticsearchUrl(): string {
    return encodeURI(`${this.elasticsearchEndpointUrl}/${this.communityId}/elasticsearch`)
  }

}
