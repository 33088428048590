import { INITIALIZING_APPLICATION } from 'app/constants'
import * as ReduxObservable from 'redux-observable'
import {switchMap} from 'rxjs/operators'

export const ALL_METADATA = 'ALL_METADATA'

export const loadMetadata = (action$, state$, {entityMetadata}) =>
  action$.ofType(INITIALIZING_APPLICATION)
    .pipe(
      switchMap(() =>
        entityMetadata.loadFromService()
          .then(() => ({
            type: ALL_METADATA,
            metadata: entityMetadata.allMetadata(),
          })),
      ))

export const epic = ReduxObservable.combineEpics(loadMetadata)
