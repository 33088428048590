import { GridCellProps } from '@progress/kendo-react-grid'
import React from 'react'
import { tryParseJSON } from './utility'

export interface EditCellState {
  drop: boolean,
}

export interface EditCellProps extends GridCellProps {
 displayfieldName: string,
 fieldName: string,
 getCustomFieldName?: any
}

export class JsonCell<P extends EditCellProps, S extends EditCellState> extends React.Component<P, S> {
  constructor(p: P, initialState: any = {}) {
    super(p)
    this.state = {
      ...initialState,
      drop: false,
    }
  }

  public render() {
    try {
    const data = tryParseJSON(this.props.dataItem[this.props.fieldName])
    return(<td>{this.props.getCustomFieldName ? this.props.getCustomFieldName(data[this.props.displayfieldName]) :
      data[this.props.displayfieldName] ? data[this.props.displayfieldName] : ''}</td>)
    } catch (e) {
      // Retry parsing with each json property
      return this.retryParsingField()
    }
  }

  private retryParsingField = () => {
    try {
      const data = this.props.dataItem[this.props.fieldName].split('\",')
      const newData = data.map((item: string, index: number) => {
        if ( index === 0 ) {
          return JSON.parse(`${item}"}`)
        } else {
          try {
            return JSON.parse(`{${item}"}`)
          } catch (e) {
            return {}
          }
        }
      })
      const field = newData.find((item: any) => item[this.props.displayfieldName] !== undefined)
      const fieldValue = field[this.props.displayfieldName] ? field[this.props.displayfieldName] : ''

      return(<td>{this.props.getCustomFieldName ? this.props.getCustomFieldName(fieldValue) : fieldValue}</td>)
      } catch (e) {
        // Return blank value if not able to parse json after retry.
        return <td></td>
      }
  }
}
