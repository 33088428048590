interface GTMEventPayload {
  name: string,
  category?: string,
  action?: string,
  label?: string,
  value?: string,
}

export const sendGTMEvent = (payload: GTMEventPayload) => {
  const {action, category, label, name, value} = payload
  window.dataLayer.push({
    event: name,
    eventProps: {
      category,
      action,
      label,
      value,
    },
  })
}
