/***************************************************************
 GENERIC DATA GRID
 Use this class to display editable or read only grids that display
 data from a Dataset and bind edits to an entity
****************************************************************/
import { orderBy, SortDescriptor, toODataString } from '@progress/kendo-data-query'
import { Grid, GridItemChangeEvent, GridNoRecords } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip'

import classnames from 'classnames'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import {RasaContext} from 'context'
import { HttpMethod } from 'generic/ajaxWrapper'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { Dataset } from 'generic/dataset'
import { isEqual } from 'lodash'
import React, { Component } from 'react'
import * as Errors from 'shared_server_client/types/error'
import { DatasetContainer, DatasetParam } from './dataset'

export interface GridDataChangeEvent {
  change: any,
  itemId: number | string,
  success: boolean,
}

export type GridDataChange = (e: GridDataChangeEvent) => void

export interface DataState {
  take: number,
  skip: number,
  sort: SortDescriptor[],
}

export interface RasaDataGridState<T> {
  dataContainer: DatasetContainer<T>,
  dataState: DataState,
  filteredData: any[],
  isQuerying: boolean
}

export type RasaGridFilter<T> = (t: T) => boolean

export type RasaGridTransformer<T> = (t: T) => T

export interface ChangeEvent {
  field: string,
  value: any
}

export type RasaDataNormalizer<T> = (t: T, event: ChangeEvent) => T

/** Where does data manipulation (sorting/pagination) occur */
export enum PagingSource {
  remote,
  local,
}

export interface RasaDataGridDataProps<T> {
  clearDataOnRefresh?: boolean,
  data: T[],
  datasetName: string,
  datasetMethod?: HttpMethod,
  datasetParams?: DatasetParam[],
  editDefaultState: boolean,
  entityName: string,
  forceReload?: boolean,
  gridFilter?: RasaGridFilter<T>,
  gridTransform?: RasaGridTransformer<T>,
  gridStyles?: any,
  normalizeDataOnChange?: any,
  onRowClick?: any
  pageable: boolean,
  pageSize?: number,
  pagingSource?: PagingSource,
  reloadOnChanges?: string[],
  resizable?: boolean,
  sortable?: boolean,
}

interface RasaDataGridActionProps {
  onDataChanged?: GridDataChange,
  onDataReceived?: RasaGridLoaded,
  onDataStateChange?: any,
  onItemChange?: any,
}

export interface GridListener {
  onDataChange: any
}

export interface RasaDataGridProps<T> extends RasaDataGridDataProps<T>, RasaDataGridActionProps {}

export class RasaDataGrid<T> extends Component<RasaDataGridProps<T>, RasaDataGridState<T>> {
  public static contextType = RasaContext;
  private _communityId: string = null
  private _listeners: GridListener[] = []
  constructor(props: RasaDataGridProps<T>) {
    super(props)
    this.state = {
      dataContainer: {
        data: props.data,
        total: 0,
      },
      dataState: {
        skip: 0,
        sort: [],
        take: this.props.pageSize || 10,
      },
      filteredData: [],
      isQuerying: false,
    }
    this.setIsQuerying = this.setIsQuerying.bind(this)
  }

  public addChangeListener(listener: GridListener) {
    this._listeners.push(listener)
  }

  public render() {
    const editFieldName: string = '_inEdit'

    return <div className="rasa-data-grid" style={this.props.gridStyles || {}}>
      {!this.state.isQuerying &&
      <Tooltip openDelay={100} position="top" anchorElement="target" className="rasa-grid-tooltip">
        <Grid
          {...this.props} // pass along all our props to the grid
          {...this.state.dataState}
          data={this.stateData()}
          onRowClick={this.props.onRowClick}
          total={this.props.pagingSource === PagingSource.local ? this.state.filteredData.length
            : this.state.dataContainer.total}
          onDataStateChange={(e) => this.dataStateChanged(e)}
          onItemChange={(e: GridItemChangeEvent) => this.onItemChanged(e)}
          editField={editFieldName}
          rowHeight={64}
          rowRender={this.rowRender}
        >
          <GridNoRecords><span></span></GridNoRecords>
        {this.props.children
          /*drop the children of the RasaDataGrid directly into the Grid so that our
            container can define Columns/etc and we pass em along directly*/}
        </Grid>
      </Tooltip>}
      <RasaGridLoader
        gridContainer={this}
        pageable={this.props.pagingSource !== PagingSource.local && this.props.pageable}
        sortable={this.props.pagingSource !== PagingSource.local && this.props.sortable}
        dataState={this.state.dataState}
        onDataReceived={(newData: RasaGridLoaderEvent) => this.dataReceived(newData)}
        onDataRequested={() => this.dataRequested()}
        datasetName={this.props.datasetName}
        datasetParams={this.props.datasetParams}
        datasetMethod={this.props.datasetMethod}
        editField={editFieldName}
        editDefaultState={this.props.editDefaultState}
        forceReload={this.props.forceReload}
        isQuerying={this.state.isQuerying}
        setIsQuerying={this.setIsQuerying}
      />
    </div>
  }

  public setIsQuerying = (isQuerying: boolean) => {
    if (this.state.isQuerying !== isQuerying) {
      this.setState({
        isQuerying,
      })
    }
  }

  protected dataRequested() {
    if (this.props.clearDataOnRefresh) {
      this.setState({
        dataContainer: {
          data: [],
          total: 0,
        },
      })
    }
  }

  protected dataStateChanged(event: any) {
    if (this.props.onDataStateChange) {
      this.props.onDataStateChange(event)
    }
    this.setState({...this.state, dataState: event.data})
  }

  protected dataReceived(event: RasaGridLoaderEvent) {
    this._communityId = event.communityId
    if (this.props.onDataReceived) {
      this.props.onDataReceived(event)
    }
    this.setState({dataContainer: event})
  }

  protected onItemChanged(event: GridItemChangeEvent) {
    const editedItemID = event.dataItem.id
    // update via entity objects (services)
    // STEP 1: get the entity object
    // DO NOT PASS IN recordId - that would result in getEntityObject
    // loading up the record which is not required in our situation
    // as we are just updating a single field
    this.context.entityMetadata.getEntityObject(this.props.entityName).then(
                      (entityObject: BaseClientEntity) => {
                        // STEP 2: Set the recordId - this is needed
                        // since we didn't load a record
                        entityObject.setRecordId(this._communityId, editedItemID)

                        let updatePromise: any = null

                        // STEP 3: set the data field in the entity object
                        if (event.field === 'is_archived') {
                          if (event.value === false) {
                            entityObject.data.is_archived = false
                            updatePromise = entityObject.save()
                          } else {
                            updatePromise = entityObject.archive()
                          }
                        } else {
                          entityObject.data[event.field] = event.value
                          updatePromise = entityObject.save()
                        }

                        // STEP 4: call .save() which will execute the
                        // required services calls to get the data over
                        // the wire and saved
                        const changeEvent: GridDataChangeEvent = {
                          change: {
                            [event.field]: event.value,
                          },
                          itemId: editedItemID,
                          success: false,
                        }
                        updatePromise.then(() => {
                          if (this.notifyOnChange(event)) {
                            this._listeners.forEach((l) => l.onDataChange(event))
                          }
                          if (this.props.onDataChanged) {
                            this.props.onDataChanged({
                              ...changeEvent,
                              success: true,
                            })
                          }
                        }).catch(() => {
                          if (this.props.onDataChanged) {
                            this.props.onDataChanged({
                              ...changeEvent,
                              success: false,
                            })
                          }
                        })
    })

    // fire event if a handler was defined in props
    if (this.props.onItemChange) {
      this.props.onItemChange(event)
    }

    // Now update the state with the changes.
    // Archiving is treated as changing two attributes - is_active AND is_archived
    const entityUpdates: any = {}
    if (event.field === 'is_archived') {
      if (event.value === false) {
        entityUpdates.is_archived = false
      } else {
        entityUpdates.is_archived = true
        entityUpdates.is_active = false
      }
    } else {
      entityUpdates[event.field] = event.value
    }

    const newState: RasaDataGridState<T> = {
      ...this.state,
      dataContainer: {
        ...this.state.dataContainer,
        data: this.state.dataContainer.data.map((item: any) => {
          return item.id !== editedItemID ? this.normalizeData(item, event) : {
            ...item,
            ...entityUpdates,
          }
        }),
      },
    }

    this.setState(newState)
  }

  private normalizeData = (item: T, event: GridItemChangeEvent) => {
    if (this.props.normalizeDataOnChange) {
      return this.props.normalizeDataOnChange(item, {
        field: event.field,
        value: event.value,
      })
    } else {
      return item
    }
  }

  private notifyOnChange(e: any) {
    return this.props.reloadOnChanges && this.props.reloadOnChanges.filter((c: string) => c === e.field).length > 0
  }

  private getFilteredData(): T[] {
    if (this.state.dataContainer) {
      if (this.state.dataContainer.data) {
        if ( this.state.dataContainer.data instanceof Array) {
          let localData = this.state.dataContainer.data.filter((r: any) => this.keepRecord(r))
          if (this.props.gridTransform) {
            localData = localData.map((r: any) => this.props.gridTransform(r)).filter((x) => x)
          }
          return localData
        } else {
          // eslint-disable-next-line no-console
          console.log('GOT HERE WITHOUT ARRAY DATA', this.props.entityName, this.state.dataContainer)
        }
      }
    }
    return []
  }

  private stateData(): T[] {
    let localData = this.getFilteredData()
    if (this.props.pagingSource === PagingSource.local) {
      // Update total as per filter
      if (this.state.filteredData.length !== localData.length) {
        this.setState({
          dataState: {
            ...this.state.dataState,
            skip: 0,
          },
          filteredData: localData,
        })
      }
      // client can do sorting and paging
      if (this.props.sortable && this.state.dataState.sort) {
        localData = orderBy(localData, this.state.dataState.sort)
      }
      if (this.props.pageable) {
        localData = localData.slice(this.state.dataState.skip,
                                    this.state.dataState.skip + this.state.dataState.take)
      }
      return localData
    } else {
      // server does the paging and sorting.
      return localData
    }
  }

  private keepRecord(t: T) {
    return this.props.gridFilter ? this.props.gridFilter(t) : true
  }

  private rowRender(trElement: any, props: any) {
    return React.cloneElement(trElement, {
      className: classnames(
        trElement.props.className,
        props.dataItem && props.dataItem.is_archived ? 'is_archived' : '',
        props.dataItem && props.dataItem.is_selected ? 'is_selected' : ''),
    })
  }

}

export interface RasaGridLoaderEvent {
  communityId: string,
  data: any[],
  total: number,
  message?: string,
}

export type RasaGridLoaded = (event: RasaGridLoaderEvent) => void

export interface RasaGridListener {
  addChangeListener(listener: GridListener): void
}

interface RasaGridLoaderProps {
  datasetMethod?: HttpMethod,
  datasetName: string,
  datasetParams: DatasetParam[],
  dataState: DataState,
  editDefaultState: boolean,
  editField: string,
  forceReload?: boolean,
  gridContainer: RasaGridListener,
  isQuerying: boolean,
  onDataReceived: RasaGridLoaded,
  setIsQuerying: any,
  onDataRequested?: any,
  pageable: boolean,
  sortable: boolean,
}

export class RasaGridLoader<T> extends React.Component<RasaGridLoaderProps> implements GridListener {
  public static contextType = RasaContext
  private lastSuccess: string = null
  private lastDatasetParams: DatasetParam[] = null

  public constructor(props: RasaGridLoaderProps) {
    super(props)
    props.gridContainer.addChangeListener(this)
  }

  public onDataChange(event: any) {
    this.requestData()
  }

  public componentDidMount = () => {
    this.requestDataIfNeeded()
  }

  public componentDidUpdate(oldProps: RasaGridLoaderProps) {
    if (oldProps !== this.props) {
      this.requestDataIfNeeded()
    }
  }

  public render() {
    if (this.props.isQuerying) {
      return <RasaGridLoadingPanel />
    } else {
      return null
    }
  }

  private requestDataIfNeeded() {
    const datasetParamsChanged = !isEqual(this.lastDatasetParams, this.props.datasetParams)
    if (this.dataStateQueryString() !== this.lastSuccess || datasetParamsChanged || this.props.forceReload) {
      this.requestData()
    }
  }

  private dataStateQueryString() {
    const queryState = {
      skip: this.props.pageable ? this.props.dataState.skip : null,
      sort: this.props.sortable ? this.props.dataState.sort : null,
      take: this.props.pageable ? this.props.dataState.take : null,
    }
    return toODataString(queryState)
  }

  private requestData() {
    if (this.props.isQuerying) {
      return
    }
    this.props.setIsQuerying(true)
    this.lastDatasetParams = this.props.datasetParams
    return this.context.user.init().then(({person, activeCommunity}) => {
      const ds: Dataset = new Dataset()
      const pendingQuery = this.dataStateQueryString()
      if (this.props.onDataRequested) {
        this.props.onDataRequested()
      }
      return ds.loadCommunityDataset(
        this.props.datasetName,
        activeCommunity.communityId,
        this.props.datasetParams,
        this.props.pageable ? this.props.dataState.take : null,
        this.props.pageable ? this.props.dataState.skip / this.props.dataState.take : null,
        this.props.sortable && this.props.dataState.sort.length > 0 ? this.props.dataState.sort[0].field : null,
        this.props.sortable && this.props.dataState.sort.length > 0 ? this.props.dataState.sort[0].dir : null,
        this.props.datasetMethod,
      ).then((newData) => {
        this.lastSuccess = pendingQuery
        this.props.setIsQuerying(false)

        if (this.props.pageable) {
          if (this.dataStateQueryString() === this.lastSuccess) {
            let mappedData: any[] = newData[0];
            if (this.props.editField && this.props.editField.length > 0) {
              mappedData = mappedData.map((item: any) =>
                        ({...item,
                      [this.props.editField]: this.props.editDefaultState}))
            }
            this.props.onDataReceived({
              communityId: activeCommunity.communityId,
              data: mappedData,
              total: newData[1][0] ? newData[1][0].TotalRows : mappedData.length,
              message: this.getMessage(newData[1])
            })
          } else {
            this.requestDataIfNeeded()
          }
        } else {
          this.props.onDataReceived({
            communityId: activeCommunity.communityId,
            data: newData[0],
            total: newData[0].length,
            message: this.getMessage(newData[1])
          })
        }
      })
      .catch((err) => {
        this.lastSuccess = pendingQuery
        this.props.setIsQuerying(false)
        this.props.onDataReceived({
          communityId: activeCommunity.communityId,
          data: [],
          total: 0,
        })
        if (err && err.response) {
          if ( (err.response.error && err.response.error.code === 'ETIMEDOUT')
            || (err.response.name === 'TimeoutError')) {
            this.context.store.dispatch(Flash.showFlashError(Errors.getErrorMessage(Errors.ErrorCode.TimeoutError)))
          } else if (err.response.error.code === Errors.ErrorCode.InvalidInputParameter) {
            this.context.store.dispatch(Flash.showFlashError(Errors.getErrorMessage(Errors.ErrorCode.InvalidInputParameter)))
          } else {
            this.context.store.dispatch(Flash.showFlashError(Errors.getErrorMessage(Errors.ErrorCode.GeneralException)))
          }
        } else {
          this.context.store.dispatch(Flash.showFlashError(Errors.getErrorMessage(Errors.ErrorCode.GeneralException)))
        }
      })
    })
  }

  private getMessage = (payload: any) => {
    if (payload && typeof payload === 'object' && payload.message) {
      return payload.message
    }

    return null
  }
}

class RasaGridLoadingPanel extends React.Component {
  public render() {
    return (
        <div className="grid-balls">
          <Loading size="64" />
        </div>
    )
  }
  /*
    const gridContent = document && document.querySelector('.k-grid-content');
    return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
  */
}
