import { createCompletePurchaseEvent } from 'google-analytics'
import { parse } from 'query-string'
import ReactGA4 from 'react-ga4'
import * as ReduxObservable from 'redux-observable'
import {filter, map, tap} from 'rxjs/operators'
import * as StoreConstants from 'store/constants'
import { writeLog } from 'utils'
import * as Router from '../router'
import * as Constants from './constants'
import { ConsentItem, Event4 } from './types'
import { createConsentEvent } from './utils'

const onEnter: ReduxObservable.Epic = (action$) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      map(({pathname}) => pathname),
      tap(() => ReactGA4.send('pageview')),
      map((pathname) => ({type: Constants.SEND_PAGE_VIEW, pathname})),
    )

const sendGa4Event: ReduxObservable.Epic = (action$, state$, {history, user})  =>
  action$
    .ofType(Constants.SEND_EVENT_4)
    .pipe(
      tap((action: {event: Event4}) => ReactGA4.event(action.event.name, action.event.payload)),
      tap((action) => {
        const pernsonObject = (user || {}).person || {}
        const activeCommunityObject = (user || {}).activeCommunity || {}
        const activeCommunityDataObject = activeCommunityObject.data || {}
        const billingInfoObject = (activeCommunityObject || {}).billingInfo || {}

        const userInfo: any = {}
        const communityInfo: any = {}

        userInfo.email = pernsonObject.email || ''
        userInfo.name = pernsonObject.fullName || ''
        userInfo.owner_email = activeCommunityDataObject.owner_email || ''

        communityInfo.plan = (billingInfoObject.currentPlan || {}).name || ''
        communityInfo.community_name = activeCommunityObject.communityName || ''
        communityInfo.community_identifier = activeCommunityObject.communityId || ''

        writeLog({
        community: window.localStorage.getItem(StoreConstants.SELECTED_COMMUNITY),
        event: action.event,
        type: 'GA4',
        user: userInfo,
        activeCommunityInfo: communityInfo,
      })}),
      map((originalEvent) => ({type: Constants.SEND_EVENT_COMPLETE, originalEvent})),
    )

const onUpgrade: ReduxObservable.Epic = (action$, state$, {history, user}) => {
  const gaEvent: Event4 = createCompletePurchaseEvent(sessionStorage)
  return action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => {
        return history.location.pathname.match(/\/settings/gm) &&
               history.location.search.match(/hostedpage_id=/gm)
      }),
      map(() => ({type: Constants.SEND_EVENT_4, event: gaEvent})),
    )
}

const setUpgradeFromEmail: ReduxObservable.Epic = (action$, state$, { history, user }) => {
  return action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => (history.location.pathname + history.location.search)
        .match(/\/settings\/account\?upgrade_from_email=/gm)),
      map(() => parse(history.location.search)),
      map(({ upgrade_from_email }) => {
        sessionStorage.setItem(Constants.UPGRADE_FROM, upgrade_from_email.toString() + 'Email')
        return ({ type: 'SET_SESSION_COMPLETE' })
      }),
    )
}

const createConsentItem = (action): ConsentItem => {
  return {
    source: action.data.url.includes('csv') ? 'csv' : 'integration',
    submit: action.type.includes('SUBMIT'),
  }

}
const onConsent: ReduxObservable.Epic = (action$, state$, { history, user }) => {
  return action$.ofType(Constants.IMPORT_CONTACTS_CONSENT_YES, Constants.IMPORT_CONTACTS_CONSENT_NO)
    .pipe(
      filter((action) => action.data),
      map((action) => ({ type: Constants.SEND_EVENT_4, event: createConsentEvent(createConsentItem(action)) })),
    )
}

export const epic = ReduxObservable.combineEpics(
  onConsent,
  onEnter,
  onUpgrade,
  sendGa4Event,
  setUpgradeFromEmail,
)
