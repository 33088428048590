import { Dataset, DatasetParam } from './dataset'

type StateDataSetType = [string?, string?, number?, number?, string?, string?, DatasetParam[]?]

export class StaticDataset extends Dataset {
  public static url(...args: StateDataSetType) {
    if (!StaticDataset.instance) {
      StaticDataset.instance = new StaticDataset()
    }

    return StaticDataset.instance.getDataUrl(...args)
  }

  private static instance: StaticDataset
}
