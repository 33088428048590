import {RasaContext} from 'context'
import * as History from 'history'
import { isEmpty } from 'lodash'
import * as Query from 'query-string'
import {Component} from 'react'

interface BrowserComponentState {
  queries?: any,
  hash?: any,
}

const emptyObject: any = {}

export abstract class RasaBrowserComponent<P, S = any> extends Component<P, S & BrowserComponentState> {
  public static contextType = RasaContext

  constructor(props: P, initialState: S = emptyObject) {
    super(props);
    this.state = {
      ...initialState,
      queries: Query.parse(location.search) || {},
      hash: location.hash || '',
    }
    this.getHash = this.getHash.bind(this)
    this.setHash = this.setHash.bind(this)
    this.getQuery = this.getQuery.bind(this)
    this.setQuery = this.setQuery.bind(this)
  }

  public getHash(): string {
    return this.state.hash || ''
  }

  public setHash(hash: string) {
    return new Promise((resolve) => this.setState({
      hash,
    }, () => {
      this.updateBrowserURL()
      resolve(true)
    }))
  }

  public getQuery(key: string, defaultValue: any = ''): string {
    return this.state.queries ? this.state.queries[key] || defaultValue : defaultValue
  }

  public setQuery(key: string, value: any, allowEmpty: boolean = false) {
    return new Promise((resolve) => this.setState({
      ...this.state,
      [key]: value,
    }, () => {
      if (isEmpty(value) && !allowEmpty) {
        if (this.getQuery(key)) {
          delete this.state.queries[key]
          this.updateBrowserURL()
        }
        resolve(true)
      } else {
          this.setState({
            queries: {
              ...this.state.queries,
              [key]: value,
            },
          }, () => {
            this.updateBrowserURL()
            resolve(true)
          })
        }
    }))
  }

  public setQueries(queries: any) {
    Object.keys(queries).forEach((key) => {
      this.setState({
        ...this.state,
        [key]: queries[key],
      })
    })
    this.setState({
      ...this.state,
      queries,
    }, () => this.updateBrowserURL())
  }

  public clearQueryParams() {
    Object.keys(this.state.queries).forEach((key) => {
      this.setState({
        ...this.state,
        [key]: '',
      })
    })
    this.setState({
      ...this.state,
      queries: {},
    }, () => this.updateBrowserURL())
  }

  public loadStateFromUrl() {
    return new Promise((resolve) => {
      return this.setState({
        ...this.state,
        ...this.state.queries,
      }, () => {
        resolve(true)
      })
    })
  }

  private updateBrowserURL = () => {
    const history = History.createBrowserHistory()
    history.push({
      pathname: history.location.pathname,
      hash: this.state.hash,
      search: Query.stringify(this.state.queries),
    })
  }
}
