/***************************************************************
 GENERIC Dataset wrapper - this is a non-visual wrapper object
 that knows how to get a dataset from the server
****************************************************************/
import { AjaxWrapper, HttpMethod } from './ajaxWrapper'

export interface DatasetContainer<T> {
  data: T[],
  total: number,
}

export interface DatasetParam {
  param: string,
  value: any,
}

export class Dataset {
  private readonly datasetEndpointUrl: string = '/dataset'

  public loadGlobalDataset(
    datasetName: string,
    params?: DatasetParam[],
    pageSize?: number,
    startPage?: number,
  ): Promise<any> {
    return this.loadCommunityDataset(datasetName, null, params, pageSize, startPage, null, null)
  }

  public loadCommunityDataset(
    datasetName: string,
    community?: string,
    params?: DatasetParam[],
    pageSize?: number,
    startPage?: number,
    sortBy?: string,
    sortDir?: string,
    httpMethod?: HttpMethod,
  ): Promise<any> {
    if (datasetName === null) {
      return Promise.reject('datasetName must be provided')
    } else {
      if ( httpMethod === HttpMethod.POST ) {
        const url: string = AjaxWrapper.getServerUrl() +
        this.getDataUrl(datasetName, community, pageSize, startPage, sortBy, sortDir, [])
        const postParams: any = params.reduce((acc, p) => {
          acc[p.param] = p.value
          return acc
        }, {})
        return AjaxWrapper.ajax(url,HttpMethod.POST, postParams)

      } else {
        const url: string = AjaxWrapper.getServerUrl() +
        this.getDataUrl(datasetName, community, pageSize, startPage, sortBy, sortDir, params);
        return AjaxWrapper.ajax(url, HttpMethod.GET, null)
      }
    }
  }

  // override this method to change the Server URL for loading a particular entity type
  protected getDataUrl(
    datasetName: string,
    community: string,
    pageSize: number,
    startPage: number,
    sortBy: string,
    sortDir: string,
    params: DatasetParam[],
  ): string {
    // the caller to this function must make sure user is loaded
    const pageParams: DatasetParam[] = (pageSize || startPage) ? [
      {
        param: 'startPage',
        value: startPage || 0,
      },
      {
        param: 'pageSize',
        value: pageSize || 50,
      },
    ] : []

    const sortParams: DatasetParam[] = sortBy ? [
      {
        param: 'sortBy',
        value: sortBy,
      },
      {
        param: 'sortDir',
        value: sortDir || 'desc',
      },
    ] : []

    const allParams: DatasetParam[] = pageParams.concat(sortParams).concat(params || [])
    const queryParams = []
    allParams.forEach((item: DatasetParam) => {
        if (item.value || item.value === 0) {
          queryParams.push(`${item.param}=${item.value}`)
        }
    })

    const endpointUrl = this.datasetEndpointUrl
    if (community) {
      return encodeURI(`${endpointUrl}/${community}/${datasetName}?${queryParams.join('&')}`)
    } else {
      return encodeURI(`${endpointUrl}/${datasetName}?${queryParams.join('&')}`)
    }
  }
}
