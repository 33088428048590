import * as Constants from './constants'
import * as Types from './types'

export const createUpgradeEvent = (source: string): Types.Event4 => {
  return {
    name: Constants.EventNames.Upgrade,
    payload: {
      coupon: source,
      promotion_id: source,
    },
  }
}

const makeGAItemPayload = (item: Types.CheckoutItem, sessionStorage: any): any => {
  const source: string = sessionStorage.getItem(Constants.UPGRADE_FROM) || 'no_source'
  return {
    coupon: source,
    currency: 'USD',
    value: item.price,
    items: [
      {
        item_id: item.id,
        item_name: item.name,
        quantity: 1,
        price: item.price,
      },
    ],
  }
}

export const createCheckoutEvent = (item: Types.CheckoutItem, sessionStorage: any): Types.Event4 => {
  sessionStorage.setItem(Constants.UPGRADE_ITEM, JSON.stringify(item))
  return {
    name: Constants.EventNames.Checkout,
    payload: makeGAItemPayload(item, sessionStorage),
  }
}

export const createCompletePurchaseEvent = (sessionStorage: any): Types.Event4 => {
  const itemString: string = sessionStorage.getItem(Constants.UPGRADE_ITEM)
  const item = itemString ? JSON.parse(itemString) : {}
  // Build the event before removing stuff from local storage
  const event: Types.Event4 = {
    name: Constants.EventNames.CompletePurchase,
    payload: makeGAItemPayload(item, sessionStorage),
  }
  // Make sure to remove it from local storage so we don't send it again
  sessionStorage.removeItem(Constants.UPGRADE_ITEM)
  sessionStorage.removeItem(Constants.UPGRADE_FROM)
  return event
}

export const createConsentEvent = (consent: Types.ConsentItem): Types.Event4 => {
  return {
    name: Constants.EventNames.ImportConsent,
    payload: {
      source: consent.source ? 'csv' : 'integration',
      submit: consent.submit ? 'YES' : 'NO',
    },
  }
}