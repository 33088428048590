import pipe from 'lodash/fp/pipe'
import prop from 'lodash/fp/prop'
import propOr from 'lodash/fp/propOr'
import {
  AggregateIntervalOptions,
  AggregateMetricOptions,
  AggregateSegmentationStrategyOptions,
  DateRangesAsDropdownOptions,
  dimensionsFiltersDropdownOptions
} from './constants';

export const selectAggs = propOr({}, 'aggregations')

export const selectBuckets = propOr([], 'buckets')

export const selectAggBuckets = (aggName: string) => pipe(
  prop(aggName),
  selectBuckets,
)

export const createAggSelector = (aggName: string) => pipe(
  selectAggs,
  selectAggBuckets(aggName),
)

export const selectValue = prop('value')

export const selectValueAgg = (aggName: string) => pipe(
  prop(aggName),
  selectValue,
)

export const FindIndexOfDateRangesOptionByValue = (searchValue: string) => {
  const index = DateRangesAsDropdownOptions.findIndex((option) => option.value === searchValue)
  return index >= 0 ? index : 0
}

export const FindIndexOfAggregateIntervalOptionByValue = (searchValue: string) => {
  const index = AggregateIntervalOptions.findIndex((option) => option.value === searchValue)
  return index >= 0 ? index : 0
}

export const FindIndexOfAggregateMetricOptionByValue = (searchValue: string) => {
  const index = AggregateMetricOptions.findIndex((option) => option.value === searchValue)
  return index >= 0 ? index : 0
}

export const FindIndexOfAggregateSegmentationStrategyOptionByValue = (searchValue: string) => {
  const index = AggregateSegmentationStrategyOptions.findIndex((option) => option.value === searchValue)
  return index >= 0 ? index : 0
}
export const FindIndexOfDimensionsFiltersDropdownOptionByValue = (searchValue: string) => {
  const index = dimensionsFiltersDropdownOptions.findIndex((option) => option.value === searchValue)
  return index >= 0 ? index : 0
}
