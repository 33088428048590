export const USER_AVATAR_IMG: string =
  'https://p7.hiclipart.com/preview/550/997/169/user-icon-foreigners-avatar-thumbnail.jpg'

export const MANAGE_SETTINGS_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/C585F921-CE4F-4B8D-8F85-F84C334F6F55.svg'

export const LOGOUT_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/0CED9C9D-DBC0-4016-A09D-B863653EF313.svg'

export const BILLING_PLAN_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/E8228CB5-A336-4C73-8B23-F2733D2532A6.svg'

export const CONTENT_POOL_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/15EC1FC2-30F2-4DF5-A03F-586325F7BAE5.svg'

export const CONTENT_POOL_OPTION_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/3FC2EACA-BA79-4A4E-B77E-06E081E4B790.svg'

export const SUBSCRIBERS_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/3FC2EACA-BA79-4A4E-B77E-06E081E4B790.svg'

export const ADMIN_IMG: string =
  'https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/3FC2EACA-BA79-4A4E-B77E-06E081E4B790.svg'
