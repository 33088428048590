import isNil from 'lodash/isNil'
import {EntityFieldMetadataItem, EntityMetadataBase, EntityMetadataItem} from 'shared_server_client/entityMetadataBase'

import {AjaxWrapper, HttpMethod} from './ajaxWrapper'
import {BaseClientEntity, RecordIdType} from './baseClientEntity'

const subclassDirectory = '../entity-subclasses/'

export class EntityMetadataClient extends EntityMetadataBase {
  private _bLoaded: boolean = false;

  public registerSimple(name: string, tableNames?: string[], fields?: EntityFieldMetadataItem[]) {

    const nameWithUpper: string = name.substr(0, 1).toUpperCase() + name.substring(1)

    super.register(new EntityMetadataItem(name,
       null,
       null,
      'spGet' + nameWithUpper,
      'spCreate' + nameWithUpper,
      'spUpdate' + nameWithUpper,
      'spDelete' + nameWithUpper,
      tableNames,
      fields));
  }

  public loadFromService() {
    if (this._bLoaded) {
      return Promise.resolve({})
    } else {
      // This function will load up the entity data from a service call
      const url: string = AjaxWrapper.getServerUrl() + this.getEntityMetadataUrl();
      return AjaxWrapper.ajax(
        url,
        HttpMethod.GET,
        null).then((newData) => {
          this.loadEntitiesFromJson(newData)
          this._bLoaded = true
        })
    }
  }

  public getEntityObject(entityName: string, communityId?: string, recordId?: RecordIdType): Promise<BaseClientEntity> {
    const md: EntityMetadataItem = this.find(entityName)
    if (md) {
      if (md.entityClientClass && md.entityClientClass.length > 0) {
        return import(subclassDirectory + md.entityClientClass).then((theModule) => {
          return this.loadEntityObject(theModule.createEntityObject(), communityId, recordId)
        })
      } else {
        return this.loadEntityObject(new BaseClientEntity(entityName), communityId, recordId)
      }
    }
    return Promise.reject('Couldn\'t find EntityMetaData for: ' + entityName);
  }

  protected loadEntityObject(
    obj: BaseClientEntity,
    communityId: string,
    recordId: RecordIdType = null,
  ): Promise<BaseClientEntity> {
    return isNil(recordId) ? Promise.resolve(obj) : obj.load(communityId, recordId).then(() => obj)
  }

  protected loadIfNeeded() {
    if (this._bLoaded) {
      return Promise.resolve({})
    } else {
      return this.loadFromService()
    }
  }

  // override this method to change the Server URL for loading a particular entity type
  protected getEntityMetadataUrl(): string {
    return '/entity-metadata'
  }

  private loadEntitiesFromJson(entities: any) {
    if (entities && entities.length > 0) {
      // go through the result and load up the meta-data
      entities.forEach((e: any) => {
        this.register(new EntityMetadataItem(e.name,
          e.entityServerClass,
          e.entityClientClass,
          e.spGet,
          e.spCreate,
          e.spUpdate,
          e.spDelete,
          e.tableNames,
          e.fields.map((item: any) =>
            new EntityFieldMetadataItem(
              item.name,
              item.type,
              item.spName,
              item.update,
              item.create,
              item.isId,
            ),
          )))
      })
    }
  }
}
