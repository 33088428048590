import {RasaContext} from 'context'
import {BaseClientEntity, RecordIdType} from './baseClientEntity'
import * as Generic from './genericRedux'
import { RasaBrowserComponent } from './rasaBrowserComponent'

export enum EntityFieldPropMapType {
  direct,
  custom,
}

export class EntityFieldPropMap {
  public entityFieldName: string
  public propertyName: string
  public mapType: EntityFieldPropMapType
  public mapToPropFunction?: any
  public mapToEntityFieldFunction?: any
}

export class EntityPropsMap {
  public fieldMap: EntityFieldPropMap[] = []
  public mapAllDirect: boolean = false // set this to true if you want to map
                    // ALL fields in the EntityObject but NOT
                    // in the fieldMap to a property on the props object
  constructor(fieldMap?: EntityFieldPropMap[], mapAllDirect?: boolean) {
    if (fieldMap) {
      this.fieldMap = fieldMap
    }
    if (mapAllDirect) {
      this.mapAllDirect = mapAllDirect
    }
  }
}

interface ComponentProps extends Generic.ComponentActionProps {
  [key: string]: any
}

interface ComponentState {
  isDirty: boolean,
  isLoading: boolean,
  isSaving: boolean,
  communityId?: RecordIdType,
}

const emptyObject: any = {}

export abstract class RasaReactComponent<P extends ComponentProps, S = any> extends
  RasaBrowserComponent<P, S & ComponentState> {
  public static contextType = RasaContext
  private _entityPropsMap: EntityPropsMap
  private _entityName: string

  constructor(props: P, entityName: string, initialState: S = emptyObject) {
    super(props, {
      ...initialState,
      isDirty: false,
      isLoading: false,
      isSaving: false,
      communityId: null,
    })
    this._entityName = entityName
  }

  public get entityName(): string {
    return this._entityName
  }

  public get entityPropsMap(): EntityPropsMap {
    return this._entityPropsMap
  }

  public get isDirty(): boolean {
    return this.state.isDirty
  }

  public get isSaving(): boolean {
    return this.state.isSaving
  }

  public propertyChanged(propertyName: string, data: any) {
    this.setState({
      ...this.state,
      isDirty: true,
    })
    this.props.propertyChanged(propertyName, data)
  }

  public propertiesChanged(data: any) {
    this.setState({
      ...this.state,
      isDirty: true,
    })
    this.props.propertiesChanged(data)
  }

  public selectCommunity(communityId: string) {
    this.setState({
      ...this.state,
      communityId,
    })
  }

  public loadRecord(communityId: string, recordId: RecordIdType): Promise<boolean> {
    this.setState({
      ...this.state,
      communityId,
      isLoading: true,
    })
    return this.context.entityMetadata.getEntityObject(this.entityName, communityId, recordId).then((entity: any) => {
      const o = this.mapEntityObjectToProps(entity)
      this.clearComponentState()
      this.updateComponentState(o)
      this.setState({
        ...this.state,
        isDirty: false,
        isLoading: false,
      })
      return Promise.resolve(true)
    }).catch((err) => {
      this.clearComponentState()
      this.setState({
        ...this.state,
        isDirty: true,
        isLoading: false,
      })
      throw err
    })
  }

  public saveRecord(recordId: RecordIdType): Promise<any> {
    this.setState({
      ...this.state,
      isSaving: true,
    })
    // actually test a load here instead of a save for now
    return this.context.entityMetadata.getEntityObject(this.entityName).then((entity) => {
      entity.setRecordId(this.state.communityId, recordId)
      this.mapPropsToEntityObject(entity)
      return entity.save().then((e) => {
        this.setState({
          ...this.state,
          isDirty: false,
          isSaving: false,
        })
        return Promise.resolve(e)
      })
    })
  }

  /// This is a utility method is implemented to map properties from the
  //  entityObject which comes from the service layer, and map it to the
  /// properties specific to each display Component. This method
  /// returns an object with props on it that are appropriate for use
  /// in the local object
  ///
  /// The default implementation uses the entityPropMap information to
  /// map the entity object to the properties. entityPropMap supports
  /// field-specific custom mapping. You should attempt to implement functionality
  /// using the field-specific custom mapping whenever possible and use
  /// an override of this method in a sub-class as a secondary option because
  /// using the base class will handle most of the fields in most cases
  /// and your custom field map function can handle the specifics of the
  /// small number of fields that require custom processing
  public mapEntityObjectToProps(entityObject: BaseClientEntity): object {
    const ret: any = {}

    if (!this.entityPropsMap || this.entityPropsMap.mapAllDirect) {
      // maps all fields from the entity to our props
      // either because we got TRUE for mapAllDirect OR
      // the user didn't provide a map at all which means it is by
      // definition a 100% 1:1 map between entityFields and props
      Object.keys(entityObject.data).forEach((key: any) => ret[key] = entityObject.data[key])
    } else if (this.entityPropsMap) {
      this.entityPropsMap.fieldMap.forEach((item: EntityFieldPropMap) => {
        switch (item.mapType) {
          case EntityFieldPropMapType.direct:
            // just a 1:1 mapping from entityObject to the new props
            ret[item.propertyName] = entityObject.data[item.entityFieldName]
            break;
          case EntityFieldPropMapType.custom:
            ret[item.propertyName] = item.mapToPropFunction(this, entityObject)
            break;
        }
      })
    }
    return ret;
  }

  public mapPropsToEntityObject(entityObject: BaseClientEntity): void {
    if (!this.entityPropsMap || this.entityPropsMap.mapAllDirect) {
      // maps all fields from the props to our entity
      const keys: string[] = Object.keys(this.props.data)
      for (const key of keys) {
        entityObject.data[key] = this.props.data[key]
      }
    }

    if (this.entityPropsMap) {
      for (const item of this.entityPropsMap.fieldMap) {
        switch (item.mapType) {
          case EntityFieldPropMapType.direct:
            // just a 1:1 mapping from entityObject to the new props
            entityObject.data[item.entityFieldName] = this.props.data[item.propertyName]
            break;
          case EntityFieldPropMapType.custom:
            entityObject.data[item.entityFieldName] = item.mapToEntityFieldFunction(this)
            break;
        }
      }
    }
  }

  // implemented as a function instead of setter since this is
  // protected and the getter is public and TS doesn't like mixed
  // access public/protected on getter/setter
  protected setEntityPropsMap(propsMap: EntityPropsMap) {
    this._entityPropsMap = propsMap;
  }

  protected clearComponentState() {
    this.props.clear()
  }

  /// This method handles the mapping of Component State (which is in the Redux Store)
  /// from a new object - the updatedData parameter
  ///  * updatedData - the data to be updated into the state
  ///  * forceAllProps - if this is set to true, ALL props are updated via Redux Action Creator calls
  ///            by default the method will only update the properties that have changed from
  ///            the current state
  protected updateComponentState(updatedData: any, forceAllProps?: boolean) {
    const keys: string[] = Object.keys(updatedData);
    keys.map((property: string) => {
      if (forceAllProps || updatedData[property] !== this.props[property]) {
        if (this.props.propertyChanged) {
          this.props.propertyChanged(property, updatedData[property])
        } else {
          // this is an error case where there is no action creator
          // on the props object for the property being passed in
          // however it should NOT be a hard exception because there
          // might be things on the updatedData object that do NOT
          // map to the state but are used by the caller of this function
        }
      }
    })
  }

  private _communityId: string = null
  /**
   * Invokable method that will load the data for this component when
   * the userInit is ready, OR whenever we navigate to the route
   * identified by the route prefix - in which case, we'll pluck the ID from the
   * route.
   */
  protected loadOnRouting(initialId: string, routePrefix: string = null, upsertComponent: boolean = null) {
    this.context.user.init().then(({person, activeCommunity}) => {
      this._communityId = activeCommunity.communityId
      return this.loadRecord(activeCommunity.communityId, initialId)
    })
    if (routePrefix && routePrefix.length > 0) {
      this.context.history$.subscribe((h) => {
        if (h[0].pathname.startsWith(routePrefix)) {
          if (upsertComponent) {
            return this.loadRecord(this._communityId,  this.context.store.getState().app.params.id || null)
          } else {
            return this.loadRecord(this._communityId,  this.context.store.getState().app.params.id || initialId)
          }
        }
      })
    }
    return Promise.resolve(true)
  }
}
