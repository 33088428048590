/*********************************************
 * This file contains the base state that we are using for testing the application.
 * It is also useful for looking at an example of the shape of state fully populated
*/

const _defaultSetupChecklistState = {
  /// These steps will actually be stored in Appcues, they are here locally as well for convenience....
  stepStatus: [
    {
      id: 1,
      name: 'Design your template',
      numSteps: 1,
      stepsComplete: 0,
    },
    {
      id: 2,
      name: 'Create content pool',
      numSteps: 1,
      stepsComplete: 0,
    },
    {
      id: 3,
      name: 'Add subscribers',
      numSteps: 1,
      stepsComplete: 0,
    },
    {
      id: 4,
      name: 'Connect your domain',
      numSteps: 1,
      stepsComplete: 0,
    },
    {
      id: 5,
      name: 'Setup delivery schedule',
      numSteps: 1,
      stepsComplete: 0,
    },
  ],
}

const _defaultAnalyticsState = {
}
const _defaultTemplatesState = {
}
const _defaultContentPoolState = {
}

export const defaultState = {
  analytics: _defaultAnalyticsState,
  contentPool: _defaultContentPoolState,
  setupChecklist: _defaultSetupChecklistState,
  templates: _defaultTemplatesState,
}
