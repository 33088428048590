// Generic Redux Functionality in this file - automates the process of
// Action, Action Creator and Reducer for any given set
// of properties.
import isNil from 'lodash/isNil'
import { connect } from 'react-redux'
import * as Redux from 'redux'
import * as Router from 'router'
import * as Modals from 'shared/modals'
import { injectReducer } from '../store/index'
import { DatasetContainer } from './dataset'

export const GENERIC_PREFIX = 'CONST_'

enum ActionType {
  CHANGE = 'change',
  CLEAR = 'clear',
  LOAD = 'load',
  GENERIC = 'generic',
}

const actionPrefix = (scope: string): string => `${GENERIC_PREFIX}_${scope}`
const changePrefix = (scope: string): string => `${actionPrefix(scope)}_${ActionType.CHANGE}`
export const propertyChangeActionType = (scope: string, propName: string): string => `${changePrefix(scope)}_${propName}`
export const propertiesClearedActionType = (scope: string): string => `${actionPrefix(scope)}_${ActionType.CLEAR}`
export const loadedActionType = (scope: string): string => `${actionPrefix(scope)}_${ActionType.LOAD}`
export const genericActionType = (scope: string): string => `${actionPrefix(scope)}_${ActionType.GENERIC}`

export const MULTIPLE_PROPS_CHANGED_LITERAL: string = 'MultiplePropsChanged'

type GenericPayload = any
interface ComponentAction extends Redux.Action {
  data?: GenericPayload,
  propName?: string,
}

type DataState = any
export function createGenericReducer(scope: string, defaultState: DataState): Redux.Reducer<DataState> {
  return (state = defaultState, action: ComponentAction) => {
    if (action.type) {
      const aPrefix = actionPrefix(scope)
      if (action.type.substring(0, aPrefix.length) === aPrefix) {
        const cPrefix = changePrefix(scope)
        // MATCH FOR OUR SCOPE SINCE THIS FUNCTION IS GENERIC NEED TO TEST
        if ( action.type === loadedActionType(scope) ) {
          return state
        } else if (action.type === propertiesClearedActionType(scope)) {
          return defaultState
        } else if (action.type.substring(0, cPrefix.length) === cPrefix && !isNil(action.data)) {
          const propName: string = action.propName || action.type.substring(cPrefix.length + 1, action.type.length);
          if (propName === MULTIPLE_PROPS_CHANGED_LITERAL) {
            return {
              ...state,
              ...action.data,
            }
          } else {
            return {
              ...state,
              [propName]: action.data,
            }
          }
        }
      }
    }
    // base case - if we get here it wasn't a match for the scope, or the action type was blank
    return state;
  }
}

export function createGenericDatasetReducer(scope: string, defaultState: DataState): Redux.Reducer<DataState> {
  return (state = defaultState, action: ComponentAction) => {
    if (action.type && action.data) {
      if ( action.type === loadedActionType(scope) ) {
        return action.data
      }
    }
    // base case - if we get here it wasn't a match for the scope, or the action type was blank
    return state;
  }
}

export type GenericPropertiesChangeActionCreator = (data: GenericPayload) => ComponentAction

const createPropertiesChangeAction = (scope: string): GenericPropertiesChangeActionCreator => {
  const genericChangedAction: GenericPropertiesChangeActionCreator = (data: GenericPayload) => ({
    data,
    MULTIPLE_PROPS_CHANGED_LITERAL,
    type: propertyChangeActionType(scope, MULTIPLE_PROPS_CHANGED_LITERAL),
  })
  return genericChangedAction
}

export type GenericPropertyChangeActionCreator = (propName: string, data: GenericPayload) => ComponentAction

const createPropertyChangeAction = (scope: string): GenericPropertyChangeActionCreator => {
  const genericChangedAction: GenericPropertyChangeActionCreator = (propName: string, data: GenericPayload) => ({
    data,
    propName,
    type: propertyChangeActionType(scope, propName),
  })
  return genericChangedAction
}

export type GenericPropertyClearActionCreator = () => ComponentAction

const createPropertiesClearAction = (scope: string): GenericPropertyClearActionCreator => {
  return () => ({
    type: propertiesClearedActionType(scope),
  })
}

export type DatasetLoadedAction = (data: GenericPayload) => ComponentAction
const createDatasetLoadedAction = (scope: string): DatasetLoadedAction => {
  const datasetLoadedAction: DatasetLoadedAction = (data: GenericPayload): ComponentAction => ({
    data,
    type: loadedActionType(scope),
  })
  return datasetLoadedAction
}

export type GenericAction = (data: GenericPayload) => ComponentAction
const createGenericAction = (scope: string): GenericAction => {
  const genericAction: DatasetLoadedAction = (data: GenericPayload): ComponentAction => ({
    data,
    type: genericActionType(scope),
  })
  return genericAction
}

export interface ComponentActionProps {
  clear?: GenericPropertyClearActionCreator,
  datasetLoaded?: DatasetLoadedAction,
  notify?: GenericAction,
  propertyChanged?: GenericPropertyChangeActionCreator,
  propertiesChanged?: GenericPropertiesChangeActionCreator,
  push?: any,
  setLeavePageWarning?: any,
}

function createReduxActionList (scope: string): ComponentActionProps {
  return {
    clear: createPropertiesClearAction(scope),
    datasetLoaded: createDatasetLoadedAction(scope),
    notify: createGenericAction(scope),
    push: Router.push,
    propertyChanged: createPropertyChangeAction(scope),
    propertiesChanged: createPropertiesChangeAction(scope),
    setLeavePageWarning: Router.setLeavePageWarning,
  }
}

export function createConnect(theClass: any, scope: string) {
  const _mapStateToProps = (state: any) => {
    if (!state[scope]) {
      state[scope] = {} //default blank object if not previously defined
    }

    return state[scope];
  }
  return connect(_mapStateToProps, createReduxActionList(scope))(theClass);
}

export function createConnectWithRef(theClass: any, scope: string) {
  const _mapStateToProps = (state: any) => {
    if (!state[scope]) {
      state[scope] = {} //default blank object if not previously defined
    }

    return state[scope];
  }
  return connect(_mapStateToProps, createReduxActionList(scope), null, {forwardRef: true})(theClass);
}

/**
 * This method registers a component that manages all of its state at the root level.
 *
 * @param theClass: Component Class
 * @param scope: Root key for locating state in the redux store
 * @param defaultState : Default state
 */
export function registerNewComponent<T>(theClass: any, scope: string, defaultState: T) {
  const theWrapper = createConnect(theClass, scope)
  injectReducer(scope, createGenericReducer(scope, defaultState))
  return theWrapper;
}

/**
 * This method registers a component with ref enabled that manages all of its state at the root level.
 *
 * @param theClass: Component Class
 * @param scope: Root key for locating state in the redux store
 * @param defaultState : Default state
 */
export function registerNewComponentWithRef<T>(theClass: any, scope: string, defaultState: T) {
  const theWrapper = createConnectWithRef(theClass, scope)
  injectReducer(scope, createGenericReducer(scope, defaultState))
  return theWrapper;
}

export interface ComponentDataProps<T> {
  data: T,
}

export type AllComponentProps<T> = ComponentActionProps & ComponentDataProps<T>

/**
 * This method registers a component that manages its data in two standardized locations
 * in the redux store: data and view.  The view state is standard and identifies isDirty, isLoading, isUpdating
 * triggered via record actions.
 *
 * @param theClass: Component Class
 * @param scope: Root key for locating state in the redux store
 * @param defaultState : Default state
 */
export function registerNewNestedComponent<T>(theClass: any, scope: string, defaultState: T = null) {
  const theWrapper = createConnect(theClass, scope)
  injectReducer(scope, Redux.combineReducers({
    data: createGenericReducer(scope, defaultState || {}),
  }))
  return theWrapper;
}

export interface ComponentModalProps {
  modals: Modals.ModalStateProps
}

export type ModalComponentActionProps = Modals.ModalActionsProps

function createModalActionList(scope: string):
  ComponentActionProps & ModalComponentActionProps {
  return {
    ...createReduxActionList(scope),
    ...Modals.createActionList(scope),
  }
}

export type AllComponentPropsWithModal<T> = ComponentDataProps<T> & ComponentModalProps &
                                            ComponentActionProps & ModalComponentActionProps

export function createModalConnect(theClass: any, scope: string) {
  function _mapStateToProps(state: any) {
    if (!state[scope]) {
      state[scope] = {} //default blank object if not previously defined
    }

    return state[scope];
  }
  return connect(_mapStateToProps, createModalActionList(scope))(theClass);
}

/**
 * This method registers a component that manages its data in two standardized locations
 * in the redux store: data and view.  The view state is standard and identifies isDirty, isLoading, isUpdating
 * triggered via record actions.
 *
 * @param theClass: Component Class
 * @param scope: Root key for locating state in the redux store
 * @param defaultState : Default state
 */
export function registerNewComponentWithModals<T>(
  theClass: any,
  scope: string,
  modals: string[],
  defaultState: T = null,
) {
  const theWrapper = createModalConnect(theClass, scope)
  injectReducer(scope, Modals.createReducer(createGenericReducer(scope, defaultState || {}), scope, modals))
  return theWrapper;
}

export type DatasetComponentProps<T> = ComponentActionProps & ComponentDataProps<T[]>

/**
 * This method registers a dataset component that will keep its records underneath
 * a data key at the root of the redux store.
 *
 * @param theClass: Component Class
 * @param scope: Root key for locating state in the redux store
 * @param defaultState : Default state
 */
export const registerNewDatasetComponent = <T>(theClass: any, scope: string, defaultState: T[] = null) => {
  const theWrapper = createConnect(theClass, scope)
  injectReducer(scope, Redux.combineReducers({
    data: createGenericDatasetReducer(scope, defaultState || []),
  }))
  return theWrapper;
}

export interface ComponentDataContainerProps<T> {
  container: DatasetContainer<T>,
}

export type DatasetContainerComponentProps<T> = ComponentActionProps & ComponentDataContainerProps<T>

/**
 * This method registers a dataset component that will keep its records underneath
 * a data key at the root of the redux store.
 *
 * @param theClass: Component Class
 * @param scope: Root key for locating state in the redux store
 * @param defaultState : Default state
 */
export function registerNewDatasetContainerComponent<T>(theClass: any, scope: string, defaultState: T[] = null) {
  const theWrapper = createConnect(theClass, scope)
  injectReducer(scope, Redux.combineReducers({
    container: createGenericDatasetReducer(scope, defaultState || []),
  }))
  return theWrapper;
}
